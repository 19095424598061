const { axiosException } = require('../exception/axios')
const { axios } = require('../services/axios')
const {
  BACKEND_BANNERS_LISTING,
  BACKEND_BANNERS_BUSCA
} = require('../settings/kernel')

const self = {}

self.getParamsFromQueryString = (queryString) => {
  const result = {}

  const params = new URLSearchParams(queryString.slice(1))
  const entries = params.entries()
  for (const [key, value] of entries) {
    result[key] = value
  }
  return result
}

self.getParamsFromSlug = (slugString, type = '') => {
  const slugArray = slugString.split('/')
  let result = {}

  switch (type) {
    case 'offer':
      result = {
        campanha: slugArray[2] || ''
      }
      break
    default:
      result = {
        dep: slugArray[1] || '',
        sec: slugArray[2] || '',
        cat: slugArray[3] || ''
      }
      break
  }

  return result
}

self.formatFilterListToUrlParam = (filterList) => {
  return JSON.stringify(
    Object.values(filterList).map((item) => item.map((item) => item.code))
  )
}

self.formatBrandListToUrlParam = (filterList) => {
  const formatedList = Object.values(filterList).map((item) =>
    item.map((item) => item.code)
  )

  return JSON.stringify(formatedList[0] || [])
}

self.formatSelectedRangeToUrlParam = (filter) => {
  const min = filter[0].minPrice
  const max = filter[0].maxPrice
  return `min=${min}&max=${max}`
}

self.replaceUrlParams = (params, shouldPush = false) => {
  if (shouldPush) {
    window.history.pushState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?` + params)
    )
  } else {
    window.history.replaceState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?` + params)
    )
  }
}

self.getBannersCategories = async (categoryCode) => {
  try {
    const data = await axios.get(`${BACKEND_BANNERS_LISTING}${categoryCode}`)
    return data
  } catch (error) {
    throw axiosException(error, 'GET BANNER CATEGORIES LISTAGEM')
  }
}

self.getBannersSearch = async (search) => {
  try {
    const data = await axios.get(
      `${BACKEND_BANNERS_BUSCA}${encodeURIComponent(search)}`
    )
    return data
  } catch (error) {
    throw axiosException(error, 'GET BANNER SEARCH LISTAGEM')
  }
}

module.exports = self
