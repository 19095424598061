function generateLoadingCardGrid(cardsQuantity: number) {
  return Array(cardsQuantity)
    .fill(null)
    .map((_, index) => (
      <div
        key={index}
        className='h-[233px] animate-pulse rounded-2 bg-black-500 tablet:h-[436px]'
      />
    ))
}

function generateLoadingCardList(cardsQuantity: number) {
  return Array(cardsQuantity)
    .fill(null)
    .map((_, index) => (
      <div
        key={index}
        className='h-[185px] animate-pulse rounded-2 bg-black-500'
      />
    ))
}

export function ProductGridLoading() {
  return (
    <div className='grid grid-cols-1 w-full mt-16 gap-16 tablet:grid-cols-3 desktopLarge:grid-cols-5'>
      {generateLoadingCardGrid(20)}
    </div>
  )
}

export function ProductListLoading() {
  return (
    <div className='flex flex-col mt-16 gap-16 w-full'>
      {generateLoadingCardList(10)}
    </div>
  )
}

export function ProductCountLoading() {
  return (
    <div className='flex tablet:w-full my-12 tablet:self-center tablet:justify-center tablet:my-0'>
      <div className='flex w-[95px] h-[18px] animate-pulse rounded-2 bg-black-500' />
    </div>
  )
}
