import {
  ContainerFooterHover,
  ContainerFooterNoHover
} from 'components/Product/Footer/Footer.styles'
import styled, { css } from 'styled-components'

import { tv } from 'tailwind-variants'
import { ProductLinkProp } from './ProductList.types'

export const cardWrapper = tv({
  base: 'mb-16',
  variants: {
    type: {
      normal: '',
      legendary:
        'card-wrapper card-wrapper-legendary before:-top-[300%] before:-right-[16%] before:-bottom-[300%] before:-left-[16%] after:h-48',
      blackLegendary:
        'card-wrapper card-wrapper-legendary-black before:-top-[300%] before:-right-[16%] before:-bottom-[300%] before:-left-[16%] after:h-48'
    }
  }
})

export const stampWrapper = tv({
  base: 'group-hover:hidden hidden',
  variants: {
    hasStamp: {
      true: 'flex'
    }
  }
})

const WrapperRatingStars = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: ${({ theme }) => theme.rem(24)};

  .ratingCard {
    position: relative;
  }
`

export const WishlistButton = styled.button`
  display: flex;
  background-color: transparent;
`

export const AddToCartContainer = styled.div`
  display: flex;
`

const StampWrapper = styled.div``

export const WrapperFlash = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.rem(-16.5)};
  transition: all 0.2s ease;
  bottom: 0;
  width: ${({ theme }) => theme.rem(20)};
  height: ${({ theme }) => theme.rem(22)};
  overflow: hidden;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.md};
`

export const SponsoredText = styled.span`
  ${({ theme }) => theme.font(12)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black(600)};
`

export const Product = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.sm};
  background-color: #fff;
  height: ${(props) => props.theme.rem(182)};
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  text-decoration: none;
  transition: box-shadow 200ms ease;

  &:hover {
    ${ContainerFooterHover},
    ${WrapperRatingStars},
    ${SponsoredText} {
      display: flex;
    }

    ${ContainerFooterNoHover},
    ${StampWrapper} {
      display: none;
    }

    ${WrapperFlash} {
      left: ${({ theme }) => theme.rem(-16.5)};
      width: ${({ theme }) => theme.rem(62)};
    }
  }

  .priceCard {
    margin-top: 0;
  }

  .priceTextCard {
    height: auto;
  }

  .availableFooterCard, 
  .availableFooterCardHover {
    margin-top: auto;
  }
`

export const ProductLink = styled.a<ProductLinkProp>`
  display: flex;
  width: 100%;
  text-decoration: none;
  height: ${(prop) =>
    prop.hasOfferBadge ? prop.theme.rem(129) : prop.theme.rem(136)};
  margin-top: auto;
`

export const Image = styled.div<{ hasNoImage: boolean }>`
  height: 100%;
  width: ${(props) => props.theme.rem(198)};
  margin-right: ${({ theme }) => theme.spacing.sm};
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  position: relative;

  ${({ hasNoImage }) => {
    if (hasNoImage)
      return css`
        img {
          display: none;
        }
      `
  }}

  img {
    max-width: 100%;
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    width: ${({ theme }) => theme.rem(120)};
    height: ${({ theme }) => theme.rem(108)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    height: 100%;
    width: ${(props) => props.theme.rem(198)};
  }
`

export const TagsContainer = styled.div`
  height: ${(props) => props.theme.rem(20)};
  margin-top: ${({ theme }) => theme.spacing.xs};
`

export const Container = styled.div`
  display: flex;
  height: ${(props) => props.theme.rem(129)};
  margin-top: auto;
  justify-content: space-between;
  flex: 1;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.rem(380)};

  > span {
    color: ${({ theme }) => theme.colors.black(600)};
    ${({ theme }) => theme.font(12, 18, 400)}
  }

  > h2 {
    color: ${({ theme }) => theme.colors.black(800)};
    ${({ theme }) => theme.font(14, 18, 600)};
    margin-top: ${({ theme }) => theme.rem(2)};
    height: ${(props) => props.theme.rem(85)};
    white-space: normal;
    height: 400px;
  }
`

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.xxxs};
  margin-left: ${({ theme }) => theme.spacing.md};
  border-left: ${(props) => props.theme.rem(1)} solid
    ${({ theme }) => theme.colors.black(500)};
  max-width: ${(props) => props.theme.rem(246)};
  width: 100%;
  margin-top: auto;
  height: ${({ theme }) => theme.rem(136)};
`

export const PriceInformation = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: ${({ theme }) => theme.spacing.xxs} 0;

  > span {
    color: ${({ theme }) => theme.colors.black(600)};
    ${({ theme }) => theme.font(12, 21, 400)};
    text-decoration: line-through;
  }

  > h4 {
    ${({ theme }) => theme.font(24, 34, 700)};
  }

  > p {
    color: ${({ theme }) => theme.colors.black(600)};
    ${({ theme }) => theme.font(12, 18, 400)};
  }

  > div {
    padding-top: 0;
  }
`
