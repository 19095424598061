import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { ListingViewState } from './useListingView.types'

const useListingView = create<ListingViewState>()(
  devtools(
    persist(
      (set) => ({
        view: 'GRID',
        setView: (view) => set((state) => ({ ...state, view }))
      }),
      {
        name: 'listingView'
      }
    ),
    {
      name: 'useListingView'
    }
  )
)

export { useListingView }
